<template>
  <Card class="py-6 w-full px-3">
    <div v-if="!showError">
      <Card style="width: 20%">
        <Tabs
          class="mt-2 mx-2"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
        />
      </Card>
      <div>
        <Pension v-if="activeTab === 'Pension'" />
        <div v-if="activeTab === 'PAYE'" class="w-full mt-8">
          <div class="flex">
            <p v-if="!isPayeDisabled">
              <checkbox
                :value="deductEmployeeTax"
                checkbox-size="height:16px; width:16px; margin-top: 2px;"
                label="Enable PAYE to be deducted from employees payrun/salary"
                v-model="deductEmployeeTax"
              />
            </p>
            <p v-else class="font-extrabold">{{deductEmployeeTax ? 'PAYE enabled' : 'PAYE disabled'}}</p>
            <Alert
              message="This can only be set once within a financial cycle"
              variant="primary"
              :time="2"
              style="
                position: relative;
                right: 0;
                top: -5px;
                box-shadow: none;
                background: #ffffff;
                color: #e99323;
                margin-left: 45px;
              "
            />
          </div>
          <small
            v-if="isPayeDisabled"
            class="text-flame block text-sm font-bold"
            >You do not have permission to enable or disable PAYE</small
          >
          <div v-if="!isPayeDisabled" class="pl-3 mt-9 mb-5 flex flex-row">
            <Button :class="`bg-dynamicBackBtn text-white`" @click="updatePaye">
              Save Changes
            </Button>
            <Button class="text-darkPurple ml-4" @click="reversePaye">
              Revert Changes
            </Button>
          </div>
        </div>
        <div v-if="activeTab === 'NHF'" class="w-full mt-8">
          <div>
            <p v-if="!isNhfDisabled">
              <checkbox
                :value="deductEmployeeNHF"
                checkbox-size="height:16px; width:16px; margin-top: 2px;"
                label="Deduct NHF contribution from employee salary."
                v-model="deductEmployeeNHF"
              />
            </p>
            <p v-else class="font-extrabold">{{deductEmployeeNHF ? 'NHF enabled' : 'NHF disabled'}}</p>
            <small
            v-if="isNhfDisabled"
            class="text-flame block text-sm font-bold"
            >You do not have permission to enable or disable NHF</small
          >
            <p class="ml-1 mt-8 font-medium">
              <span class="text-xl font-bold">{{ rateValue }}% </span>of
              employee's basic salary
            </p>
          </div>
          <div v-if="!isNhfDisabled" class="pl-3 mt-9 mb-5 flex flex-row">
            <Button :class="`bg-dynamicBackBtn text-white`" @click="updateNhf">
              Save Changes
            </Button>
            <Button class="text-darkPurple ml-4" @click="reverseNhf">
              Revert Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
  </Card>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Card from "@/components/Card";
import Checkbox from "@/components/Checkbox";
import ErrorPage from "@/modules/Admin/error403";
import Button from "@/components/Button";
import Pension from "./Pension";

export default {
  components: {
    Tabs,
    Card,
    Button,
    ErrorPage,
    Alert,
    Checkbox,
    Pension,
  },
  data() {
    return {
      activeTab: "Pension",
      showDescription: false,
      enabledView: false,
      kpaModal: false,
      isNhfDisabled: true,
      isPayeDisabled: true,
      tabsItems: ["Pension", "PAYE", "NHF"],
      deductEmployeeTax: false,
      deductEmployeeNHF: false,
      showError: false,
      rateValue: 0,
    };
  },
  methods: {
    reversePaye() {
      this.deductEmployeeTax = false;
    },
    reverseNhf() {
      this.deductEmployeeNHF = false;
    },
    async updatePaye() {
      try {
        await this.$_savePayeSettings({
          orgId: this.$orgId,
          isEnabled: this.deductEmployeeTax,
        });
        this.$toasted.success("Settings saved", {
          duration: 5000,
        });
      } catch (error) {
        this.$toasted.error("Settings not saved", {
          duration: 5000,
        });
      }
    },
    async updateNhf() {
      try {
        await this.$_saveNhfSettings({
          orgId: this.$orgId,
          isEnabled: this.deductEmployeeNHF,
        });
        this.$toasted.success("Settings saved", {
          duration: 5000,
        });
      } catch (error) {
        this.$toasted.error("Settings not saved", {
          duration: 5000,
        });
      }
    },
    async getPaye() {
      this.enableOrDisablePaye()
      const { data } = await this.$_getPayeSettings();
      this.deductEmployeeTax = data.setting.isEnabled;
    },
    async getNhf() {
      this.enableOrDisableNhf()
      const { data } = await this.$_getNhfSettings();
      this.deductEmployeeNHF = data.setting.isEnabled;
      this.rateValue = data.setting.rateValue;
    },
    async enableOrDisablePaye() {
      try {
        await this.$handlePrivilege("paySettings", "enableOrDisablePaye");
        this.isPayeDisabled = false;
      } catch (error) {
        this.isPayeDisabled = true;
      }
    },
    async enableOrDisableNhf() {
      try {
        await this.$handlePrivilege("paySettings", "enableOrDisableNhf");
        this.isNhfDisabled = false;
      } catch (error) {
        this.isNhfDisabled = true;
      }
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege("paySettings", "accessComplianceSettings");
      this.getPaye();
      this.getNhf();
      this.showError = false;
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
</style>
