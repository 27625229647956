<template>
  <RightSideBar
    v-if="openModal"
    :loading="loading"
    submit="Save"
    @submit="save"
    :disabled-button="!isEditable"
    @close="$emit('close')"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Usage
      </p>
    </template>
    <p class="text-darkPurple">
      When do you want to start using Cloudenly to pay your employees?
    </p>
    <div>
      <div
        class="grid grid-cols-12 gap-4 text-darkPurple"
        style="margin-top:1.125rem"
      >
        <div class="col-span-6">
          <c-select
            placeholder="--Select--"
            label="First Payrun Month"
            :options="payRunMonth"
            :disabled="!isEditable"
            variant="w-full"
            v-model="month"
          />
        </div>
        <div class="col-span-6">
          <c-select
            label="First Payrun Year"
            placeholder="--Select--"
            :disabled="!isEditable"
            :options="payRunYear"
            variant="w-full"
            class="mr-2"
            v-model="year"
          />
        </div>
      </div>
      <alert
        message="Once the first payroll is processed after the initial setup, it can not be updated"
        variant="primary"
        :time="2"
        style="position: relative; right: 0; top: 35px;
             border: 1px solid #E99323; box-shadow:none; background: rgba(244, 213, 176, 0.15);; color: #E99323;"
      />
    </div>
  </RightSideBar>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "Usage",
  components: {
    RightSideBar,
    CSelect,
    Alert
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    startObject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      year: null,
      month: null,
      isEditable: false,
      payRunMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" }
      ]
    };
  },
  watch: {
    startObject() {
      this.month = this.startObject.month;
      this.year = this.startObject.year;
      this.isEditable = this.startObject.isEditable;
    }
  },
  computed: {
    payRunYear() {
      const newDate = new Date();
      return [
        { id: newDate.getFullYear() - 1, name: newDate.getFullYear() - 1 },
        { id: newDate.getFullYear(), name: newDate.getFullYear() }
      ];
    }
  },
  methods: {
    async save() {
      const payload = {
        createdBy: this.$AuthUser.id,
        orgId: this.$orgId,
        year: this.year,
        month: this.month
      };
      try {
        await this.$_createPayrollStartDate(payload);
        this.$toasted.success("Date set successfully", { duration: 5000 });
        this.$emit("close");
      } catch (error) {
        this.$toasted.error("Date not set, please contact your administrator", {
          duration: 5000
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
