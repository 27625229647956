<template>
  <div>
    <div v-if="!showError">
      <card>
        <div class="flex justify-end p-5">
          <div class="flex gap-3">
            <Button
              @click="startUsage"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn rounded"
            >
              {{isEditable ? 'Start Usage' : 'View'}}
            </Button>
          </div>
          <div v-if="isBasic && ScheduleData.length === 1" class="flex gap-3 ml-6">
            <Button
              disabled
              class="bg-dynamicBackBtn text-white rounded"
              style="background-color : #EEEEEE; color:#878E99"
            >
              Create New
            </Button>
          </div>
          <div v-else class="flex gap-3 ml-6">
            <Button
              v-if="year === null && month === null"
              class="bg-dynamicBackBtn text-white rounded"
              :style="
                year === null && month === null
                  ? `background-color : #EEEEEE; color:#878E99`
                  : ``
              "
            >
              Create New
            </Button>
            <Button
              v-else
              @click="getPayFrequencySetup"
              class="bg-dynamicBackBtn text-white rounded"
              :style="
                year === null && month === null
                  ? `background-color : #EEEEEE; color:#878E99`
                  : ``
              "
            >
              Create New
            </Button>
          </div>
        </div>
      </card>
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <sTable
          v-if="ScheduleData.length"
          :headers="ScheduleHeader"
          :items="ScheduleData"
          aria-label="earning table"
          class="w-full pt-4 mb-4"
          :has-number="false"
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.payFrequency">
              {{ handlePayFrequency(item.data.payFrequency) }}
            </div>
            <div class="capitalize" v-else-if="item.payType">
              {{ item.data.payType }}
            </div>
            <div class="capitalize" v-else-if="item.holidayPolicy">
              {{
                item.data.holidayPolicy === "sameDay"
                  ? "Same day"
                  : "Next working day"
              }}
            </div>
            <div v-else-if="item.createdAt">
              {{ $DATEFORMAT(new Date(item.data.createdAt), "dd MMMM, yyyy") }}
            </div>
            <div class="flex" v-else-if="item.id">
              <Menu left top="-15" class="p-0" margin="30">
                <template v-slot:title>
                  <icon
                    icon-name="more_icon"
                    size="xsm"
                    class="-mt-1"
                    style="padding: 3px 10px; width: 36px; height: 36px"
                  />
                </template>
                <div style="width: 158px; height: 80px" class="py-3 px-2">
                  <div
                    @click="editPaySchedule(item.data.id)"
                    class="flex py-1 px-1 h-8 cursor-pointer"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xs"
                    />
                    <p class="pt-1" style="font-size: 14px">View and Edit</p>
                  </div>
                  <div
                    @click="deleteScheduleModal(item.data.id)"
                    class="flex px-1 py-1 more h-8 cursor-pointer"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xs"
                    />
                    <p class="pt-1" style="font-size: 14px">Delete</p>
                  </div>
                </div>
              </Menu>
            </div>
          </template>
        </sTable>
        <div
          class="w-full h-auto flex flex-col"
          style="align-items: center"
          v-else
        >
          <div class="w-5/12 h-auto mt-5 flex" style="justify-content: center">
            <icon icon-name="payScheduleEmptyImg" size="l" style="width: 300px" />
          </div>
          <div class="w-8/12 h-auto text-center">
            <p class="mb-5 tracking-wide">
              An Overview of Pay Schedules can be found here. This is empty now,
              but you can start adding pay schedules to populate this area.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
    <usage
      :open-modal="openUsageModal"
      :start-object="{ month, year, isEditable }"
      @close="handleUsageClose"
    />
    <schedule-settings
      :schedule-id="scheduleId"
      :open-modal="openSettingsModal"
      :salaried="salaried"
      :hourly="hourly"
      @close="handleClose"
    />
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Pay Schedule?
          </p>
          <div class="flex">
            <Button class="bg-dynamicBackBtn text-white" @click="deleteSchedule">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Modal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";
import Usage from "./usage";

export default {
  components: {
    STable,
    Menu,
    Button,
    Card,
    ErrorPage,
    Usage,
    Modal,
    ScheduleSettings: () => import('./scheduleSettings'),
  },
  name: "PaySchedule",
  data() {
    return {
      openUsageModal: false,
      openSettingsModal: false,
      scheduleId: "",
      dialogDel: false,
      salaried: [],
      hourly: [],
      loading: true,
      month: null,
      year: null,
      isEditable: false,
      showError: false,
      editPayItems: false,
      ScheduleHeader: [
        { title: "Pay Type", value: "payType" },
        { title: "work days", value: "workdaysPerWeek" },
        { title: "Pay frequency", value: "payFrequency" },
        { title: "Holiday Policy", value: "holidayPolicy" },
        { title: "Active Since", value: "createdAt" },
        {
          title: "",
          value: "id",
          image: true,
          width: 8,
        },
      ],
      ScheduleData: [],
      itemId: "",
      modal: false,
    };
  },
  computed: {
    isBasic() {
      return this.$store.state.subscription && this.$store.state.subscription.plan === 'basic'
    },
  },
  methods: {
    async startUsage(){
      try {
         await this.$handlePrivilege("paySettings", "setupStartUsage");
         this.openUsageModal = true
      } catch (error) {
         this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    async editPaySchedule(id){
      try {
         await this.$handlePrivilege("paySettings", "editPaySchedule");
        this.scheduleId = id
        this.openSettingsModal = true
      } catch (error) {
         this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    async deleteScheduleModal(id){
      try {
         await this.$handlePrivilege("paySettings", "deletePaySchedule");
        this.scheduleId = id
        this.dialogDel = true
      } catch (error) {
         this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    async deleteSchedule() {
      try {
        await this.$_deletePaySchedules(this.scheduleId);
        this.scheduleId = "";
        this.$toasted.success("You have deleted schedule successfully", {
          duration: 5000,
        });
        this.getPaySchedules();
        this.dialogDel = false;
      } catch (error) {
        this.$toasted.error(
          error,
          { duration: 5000 }
        );
        this.dialogDel = false;
      }
    },
    handlePayFrequency(value) {
      switch (value) {
        case "twice_monthly":
          return "Twice Monthly";
        case "monthly":
          return "Monthly";
        case "daily":
          return "Daily";
        case "weekly":
          return "Four Times Monthly";
        default:
          return "";
      }
    },
    async getPaySchedules() {
      try {
        const {
          data: { data },
        } = await this.$_getPaySchedules();
        this.ScheduleData = data;
        this.loading = false;
      } catch (error) {
        this.$toasted.error(
          "An Error occured, please contact your administrator",
          { duration: 500 }
        );
      }
    },
    async getStartDate() {
      try {
        const response = await this.$_getPayrollStartDate();
        const { month, year, isEditable } = response.data.data;
        this.month = month;
        this.year = year;
        this.isEditable = isEditable;
      } catch (error) {
        throw new Error(error);
      }
    },
    handleClose() {
      this.openSettingsModal = false;
      this.scheduleId = "";
      this.getPaySchedules();
    },
    handleUsageClose() {
      this.getStartDate();
      this.openUsageModal = false;
    },
    async getPayFrequencySetup() {
      if(this.$store.state.subscription && this.$store.state.subscription.plan === 'basic' && this.ScheduleData.length === 1){
        return this.$toasted.error(
              "Your current plan doesn't allow you create more than 1 schedule",
              { duration: 5000 }
            );
      }
      try {
         await this.$handlePrivilege("paySettings", "createNewPaySchedue");
      return  this.$_getPaySchedulesSetup()
          .then(({ data }) => {
            this.salaried = data.salaried.map((item) => item.name);
            this.hourly = data.hourly;
            this.scheduleId = "";
            this.openSettingsModal = true;
          })
          .catch(() => {
            this.$toasted.error(
              "An Error occured, please contact your administrator",
              { duration: 5000 }
            );
          });
      } catch (error) {
         return this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("paySettings", "accessPayScheduleSettings");
      this.getStartDate();
      this.getPaySchedules();
      this.showError = false;
    } catch (error) {
      this.showError = true;
      this.loading = false
    }
  },
};
</script>

<style></style>
