<template>
  <div class="py-6 w-full px-3">
    <div>
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div class="w-full flex flex-col" v-else>
        <Card class="p-5">
          <div class="flex flex-col gap-6">
            <span v-feature-request
              >Your employees may choose any of the profiled pension companies
              as their preferred PFAs</span
            >

            <div class="flex items-center">
              <checkbox
                v-if="!disabledPension"
                :value="paysettings.divisions.enabledPension"
                checkbox-size="height:16px; width:16px; margin-top: 5px;"
                label="Enable Pension"
                v-model="paysettings.divisions.enabledPension"
                :disabled="disabledPension"
              />
              <p v-else class="font-extrabold">
                {{
                  paysettings.divisions.enabledPension
                    ? "Pension Enabled"
                    : "Pension Disabled"
                }}
                <small
                  v-if="disabledPension"
                  class="text-flame block text-sm font-bold"
                  >You do not have permission to enable or disable
                  pension</small
                >
              </p>
            </div>
            <div
              v-if="paysettings.divisions.enabledPension"
              class="w-full mt-8"
            >
            <div class="flex w-full">
              <div class="flex flex-col w-1/4 mr-16">
                <p class="text-sm text-darkPurple my-2 font-normal">
                  Employee Contribution
                </p>
                <div v-if="disabledContribution" class="flex w-full">
                  <div
                    class="wrapper mr-2 disabled disabled_hideOutline"
                    style="width: 150px"
                  >
                    {{ paysettings.divisions.employeeContribution }}
                  </div>
                   <span class="text-xl font-bold mt-2"> % </span>
                </div>
                <div v-else class="flex w-full">
                  <c-select
                    placeholder="0"
                    :options="contribution"
                    variant="w-full"
                    class="mr-2"
                    v-model="paysettings.divisions.employeeContribution"
                    :disabled="disabledContribution"
                    style="width: 150px"
                  />
                  <span class="text-xl font-bold mt-3"> % </span>
                </div>
              </div>
              <div class="flex flex-col w-1/4">
                <p class="text-sm text-darkPurple my-2 font-normal">
                  Organisation Contribution
                </p>
                <div v-if="disabledContribution" class="flex w-full">
                  <div
                    class="wrapper mr-2 disabled disabled_hideOutline"
                    style="width: 150px"
                  >
                    {{ paysettings.divisions.orgContribution }}
                  </div>
                   <span class="text-xl font-bold mt-2"> % </span>
                </div>
                <div v-else class="flex">
                  <c-select
                    placeholder="0"
                    :options="contribution"
                    variant="w-full"
                    class="mr-2"
                    v-model="paysettings.divisions.orgContribution"
                    :disabled="disabledContribution"
                    style="width: 150px"
                  />
                  <span class="text-xl font-bold mt-3"> % </span>
                </div>
              </div>
            </div>
              <small
                  v-if="disabledContribution"
                  class="text-flame block mt-4 text-sm font-bold"
                  >You do not have permission to edit pension contribution</small
                >
            </div>
            <div class="border w-full mt-8" />
          </div>
        </Card>
        <Card v-if="paysettings.divisions.enabledPension" class="p-5 mt-1">
          <div class="flex w-full">
            <p class="text-lg font-semibold text-darkPurple w-5/6">
              Pension Fund Administrators
            </p>
            <div
              class="text-flame font-semibold flex cursor-pointer"
              @click="handleAddNew"
            >
              <icon
                icon-name="icon-plus"
                style="margin-right: 8px; width: 20px; height: 20px"
              />
              Add New
            </div>
          </div>
          <div class="w-full flex flex-col">
            <sTable
              :headers="pensionHeader"
              :items="pensionData"
              aria-label="pension table"
              class="w-full mb-2 mt-5"
              :has-checkbox="false"
              :has-number="false"
              :loading="isFetching"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.orgPfaNo">
                  {{ item.data.orgPfaNo }}
                </div>
                <div v-else-if="item.pfaName">
                  {{ item.data.pfaName }}
                </div>
                <div class="flex h-8 mt-3" v-else-if="item.orgId">
                  <Menu left top="-50" class="p-0" margin="25">
                    <template v-slot:title>
                      <icon
                        icon-name="icon-edit"
                        class="editIco text-blueCrayola"
                      />
                    </template>
                    <div style="width: 160px; height: 85px" class="py-3 px-2">
                      <div
                        class="flex py-1 px-3 cursor-pointer"
                        @click="
                          handleEditPfa(
                            item.data.orgPfaNo,
                            item.data.pfaName,
                            item.data.id
                          )
                        "
                      >
                        <icon
                          icon-name="edit"
                          class-name="text-blueCrayola mr-2"
                          size="xs"
                        />
                        <p class="pt-1" style="font-size: 14px">Edit PFA</p>
                      </div>
                      <div
                        class="flex px-3 py-1 more h-8 cursor-pointer"
                        @click="deletePfa(item.data.id)"
                      >
                        <icon
                          icon-name="delete"
                          class-name="text-desire mr-2"
                          size="xs"
                        />
                        <p class="pt-1" style="font-size: 14px">Delete PFA</p>
                      </div>
                    </div>
                  </Menu>
                </div>
              </template>
            </sTable>
          </div>
        </Card>
        <Card class="p-5 w-full mt-1">
          <div v-if="paysettings.divisions.enabledPension">
            <div class="flex w-full">
              <p class="text-lg font-semibold text-darkPurple w-5/6">
                Pay Item Components
              </p>
            </div>
            <div class="w-full mt-6">
              <p
                class="font-semibold uppercase text-romanSilver"
                style="font-size: 10px"
              >
                Defaults:
              </p>
              <div class="flex w-full">
                <card class="w-1/6 mt-2 p-2 text-flame text-sm mr-2">
                  Basic Salary
                </card>
                <card class="w-1/6 mt-2 p-2 text-flame text-sm mr-2">
                  Transport
                </card>
                <card class="w-1/5 mt-2 p-2 text-flame text-sm mr-2">
                  Housing Allowance
                </card>
              </div>
            </div>
            <div class="border w-full mt-6" />
            <div
              class="w-full mt-6 font-semibold uppercase text-jet"
              style="font-size: 10px"
            >
              Select Additional Pay items:
            </div>
            <div class="mt-6 flex w-full">
              <div class="flex mb-4 w-full flex-wrap">
                <card
                  v-for="(item, index) in paymentItems"
                  :key="index"
                  class="mr-2 p-2 mb-4 text-sm"
                >
                  <checkbox
                    v-if="!disableComponents"
                    :label="item.payItem.name"
                    checkbox-size="height:16px; width:16px;
            margin-left: -4px; margin-top: 2px;"
                    :disabled="
                      item.payItem.slug === 'basic' ||
                      item.payItem.slug === 'transportAllowance' ||
                      item.payItem.slug === 'housingAllowance'
                    "
                    :value="item.payItem.slug"
                    v-model="selectedItems"
                  />
                  <p
                    v-else
                    :class="
                      item.payItem.slug === 'basic' ||
                      item.payItem.slug === 'transportAllowance' ||
                      item.payItem.slug === 'housingAllowance'
                        ? 'text-flame'
                        : ''
                    "
                  >
                    {{ item.payItem.name }}
                  </p>
                </card>
              </div>
            </div>
                  <small
                  v-if="disableComponents"
                  class="text-flame block mt-4 text-sm font-bold"
                  >You do not have permission to edit pay item components</small
                >
            <div class="border w-full mt-6" />
          </div>
          <div class="pl-3 mt-9 mb-5 flex flex-row">
            <Button
              :class="`bg-dynamicBackBtn text-white`"
              @click="updatePension"
              :disabled="disableBtn"
            >
              Save Changes
            </Button>
            <Button class="text-darkPurple ml-4" @click="reversePension">
              Revert Changes
            </Button>
          </div>
        </Card>
        <RightSideBar
          v-if="showModal"
          @submit="addPfaOrg"
          @close="closeSideBar"
          submit="Save"
          :button-class="`bg-dynamicBackBtn text-white`"
          :disabled-button="disableButton"
        >
          <template v-slot:title>
            <p class="text-darkPurple">
              Add a New Pension Fund Administrator (PFA)
            </p>
          </template>
          <template v-slot:subtitle>
            <p
              class="font-semibold text-darkPurple"
              style="margin-bottom: 12px"
            >
              Select a PFA and add enter your enrollment number.
            </p>
          </template>
          <p class="text-base font-semibold text-darkPurple">
            Pension Fund Administrator (PFA)
          </p>
          <p class="mt-4 text-sm text-darkPurple">PFA Name</p>
          <c-select
            placeholder="--Select--"
            :options="pfaLists"
            @onChange="handlePfaSelect($event)"
            variant="w-full"
            class="mr-2"
          />
          <p class="mt-4 text-sm text-darkPurple">Enrollment Number</p>
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            class="mt-4"
            v-model="payLoad.orgPfaNo"
          />
        </RightSideBar>
        <RightSideBar
          v-if="editModal"
          @submit="editPfaOrg"
          @close="closeSideBar"
          :button-class="`bg-dynamicBackBtn text-white`"
          :disabled-button="disableButton"
        >
          <template v-slot:title>
            <p class="text-darkPurple">Edit Pension Fund Administrator (PFA)</p>
          </template>
          <p class="text-base font-semibold text-darkPurple">
            Pension Fund Administrator (PFA)
          </p>
          <p class="mt-4 text-sm text-darkPurple">PFA Name</p>
          <c-text
            placeholder="--Enter--"
            :value="editPfa.pfaName"
            variant="w-full"
            class="mt-4"
            disabled
          />
          <p class="mt-4 text-sm text-darkPurple">Enrollment Number</p>
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            class="mt-4"
            v-model="editPfa.orgPfaNo"
          />
          <div class="border border-dashed w-full mt-4" />
        </RightSideBar>
        <Modal v-if="modal">
          <Card class="p-5 flex flex-col max-w-max">
            <div class>
              <div class="flex text-center">
                <p class="font-bold ml-4 my-5">
                  Are you sure you want to delete this PFA?
                </p>
              </div>
              <div class="flex justify-center">
                <Button class="bg-dynamicBackBtn text-white" @click="del"> Yes </Button>
                <Button
                  background-color="none"
                  class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                  @click="modal = false"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";
import Modal from "@/components/Modal";
import Loader from "@/components/Loader";
import Menu from "@/components/Menu";

export default {
  components: {
    CSelect,
    Card,
    Icon,
    STable,
    Modal,
    RightSideBar,
    CText,
    Button,
    Checkbox,
    Loader,
    Menu,
  },
  data() {
    return {
      disableBtn: false,
      disableButton: false,
      paysettings: {},
      modal: false,
      enabledView: false,
      enabledPFA: false,
      changePFA: false,
      disabledPension: true,
      disabledContribution: true,
      disableComponents: true,
      payItems: [],
      cursorType: "",
      bgButton: "bg-flame",
      savePensionBtn: true,
      payLoad: {
        orgId: "",
        pfaId: "",
        orgPfaNo: "",
        userId: "",
      },
      editPfa: {
        id: "",
        orgPfaNo: "",
        pfaName: "",
      },
      paymentItems: [],
      selectedItems: [],
      pfaLists: [],
      loading: true,
      isFetching: true,
      showModal: false,
      editModal: false,
      pensionHeader: [
        { title: "Enrollment Number", value: "orgPfaNo" },
        { title: "Pension Fund Administrator", value: "pfaName" },
        { title: "", value: "orgId" },
      ],
      pensionData: [],
      requestPayload: "",
      contribution: [
        { id: 1, name: "1" },
        { id: 1.5, name: "1.5" },
        { id: 2, name: "2" },
        { id: 2.5, name: "2.5" },
        { id: 3, name: "3" },
        { id: 3.5, name: "3.5" },
        { id: 4, name: "4" },
        { id: 4.5, name: "4.5" },
        { id: 5, name: "5" },
        { id: 5.5, name: "5.5" },
        { id: 6, name: "6" },
        { id: 6.5, name: "6.5" },
        { id: 7, name: "7" },
        { id: 7.5, name: "7.5" },
        { id: 8, name: "8" },
        { id: 8.5, name: "8.5" },
        { id: 9, name: "9" },
        { id: 9.5, name: "9.5" },
        { id: 10, name: "10" },
      ],
    };
  },
  methods: {
    reversePension() {
      this.disableBtn = true;
      this.getPensionSettings();
      this.viewPfaList();
      this.disablePension();
      this.disableContribution();
    },

    updatePension() {
      this.disableBtn = true;
      this.paymentItems = this.handlePaymentItemSelection();
      const requestPayload = {
        orgId: this.$orgId,
        divisions: this.paysettings.divisions,
        payItems: this.paymentItems,
      };
      this.$_createPensionSettings(requestPayload)
        .then(() => {
          this.disableBtn = false;
          this.$toasted.success("Pension Settings Updated", {
            duration: 3000,
          });
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error(err.message, {
            duration: 5000,
          });
          throw new Error(err);
        });
    },

    async addPfaOrg() {
      const requestPayload = this.payLoad;
      this.disableButton = true;
      await this.$_addPfa(requestPayload)
        .then(() => {
          this.showModal = false;
          this.disableButton = false;
        })
        .catch((err) => {
          this.disableButton = false;
          throw new Error(err);
        });
      await this.getPfaList();
    },
    async handleEditPfa(orgPfaNo, pfaName, id) {
      try {
        await this.$handlePrivilege("paySettings", "editPfa");
        this.editModal = true;
        this.editPfa.id = id;
        this.editPfa.orgPfaNo = orgPfaNo;
        this.editPfa.pfaName = pfaName;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    editPfaOrg() {
      const requestPayload = this.editPfa;
      this.disableButton = true;
      this.$_editPfa(requestPayload)
        .then(() => {
          this.editModal = false;
          this.disableButton = false;
          this.getPfaList();
        })
        .catch((err) => {
          this.disableButton = false;
          throw new Error(err);
        });
    },
    del() {
      this.$_deletePfa(this.requestPayload)
        .then(() => {
          this.modal = false;
          this.requestPayload = "";
          this.getPfaList();
        })
        .catch((err) => {
          this.modal = false;
          throw new Error(err);
        });
    },
    async deletePfa(Id) {
      try {
        await this.$handlePrivilege("paySettings", "deletePfa");
        this.requestPayload = Id;
        this.modal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handlePfaSelect(value) {
      const change = this.pfaLists.find((item) => item.id === value);
      this.payLoad.pfaName = change.name;
      this.payLoad.orgId = this.$orgId;
      this.payLoad.pfaId = change.id;
      this.payLoad.userId = this.$AuthUser.id;
    },
    getPensionSettings() {
      this.$_getOrgPensionSettings().then((result) => {
        this.savePensionBtn = true;
        this.disableBtn = false;
        const paysettingsResponse = result.data;
        this.paysettings = paysettingsResponse;
        const payItemsResponse = result.data.payItems;
        this.paymentItems = payItemsResponse;
        payItemsResponse.map((selectedItem) => {
          if (selectedItem.isPartOfPension) {
            this.selectedItems.push(selectedItem.payItem.slug);
          }
          return {};
        });
        this.loading = false;
      });
    },
    async getPfaList() {
      this.isFetching = true;
      await this.$_getAllPfaOrglist().then((result) => {
        this.pensionData = result.data.payload;
      });
      await this.$_getAllPfaMasterlist().then((result) => {
        this.pfaLists = result.data;
      });
      this.isFetching = false;
      this.payLoad.orgPfaNo = '';
    },
    handlePaymentItemSelection() {
      let newPayItem;
      return this.paymentItems.map((item) => {
        newPayItem = item;
        if (
          this.selectedItems.find(
            (selected) => selected === newPayItem.payItem.slug
          )
        ) {
          newPayItem.isPartOfPension = true;
        } else {
          newPayItem.isPartOfPension = false;
        }
        return newPayItem;
      });
    },
    async handleAddNew() {
      try {
        await this.$handlePrivilege("paySettings", "addNewPfa");
        this.showModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async disablePension() {
      try {
        await this.$handlePrivilege("paySettings", "enableOrDisablePension");
        this.disabledPension = false;
      } catch (error) {
        this.disabledPension = true;
      }
    },
    async disablePfaComponents() {
      try {
        await this.$handlePrivilege("paySettings", "updatePayItemComponents");
        this.disableComponents = false;
      } catch (error) {
        this.disableComponents = true;
      }
    },
    async disableContribution() {
      try {
        await this.$handlePrivilege("paySettings", "editPensionContributions");
        this.disabledContribution = false;
      } catch (error) {
        this.disabledContribution = true;
      }
    },

    async viewPfaList() {
      await this.getPfaList();
    },

    async viewPensionSettings() {
      this.getPensionSettings();
      this.viewPfaList();
      this.disablePension();
      this.disablePfaComponents();
      this.disableContribution();
    },

    closeSideBar() {
      this.showModal = false;
      this.editModal = false;
      this.payLoad.orgPfaNo = '';
    },
  },

  mounted() {
    this.viewPensionSettings();
  },
};
</script>

<style>
.bg-fadeFlame {
  background-color: rgba(241, 90, 41, 0.5);
}
</style>
