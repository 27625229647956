<template>
  <RightSideBar
    v-if="openModal"
    @close="closeModal()"
    :loading="loading"
    @submit="saveData"
    :submit="btnLabel"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple" v-if="edit">
        Annual Gross Earnings
      </p>
      <p class="text-darkPurple" v-else>Edit Rates</p>
    </template>

    <template v-slot:subtitle v-if="edit">
      <div class="flex flex-col w-full mb-5 items-center">
        <span class="mb-6">
          This shows your gross earnings as distributed across pay item. As you
          add more pay items they show up here.
        </span>
        <!-- <Doughnut /> -->

        <DonutChart
          :data="chartData"
          :options="chartOptions"
          :styles="styles"
        />
        <div class="flex justify-center w-full uppercase mt-6">
          <p>
            • Allocated
            <span class="font-extrabold mr-4">{{ annualGrossPay }}%</span>
          </p>
          <p>
            • Unallocated
            <span class="font-extrabold">{{ 100 - annualGrossPay }}%</span>
          </p>
        </div>
      </div>
    </template>
    <div class="flex gap-5 flex-col justify-center items-center" v-if="edit">
      <div class="flex gap-2 items-center" v-for="n in AppLabel" :key="n.id">
        <span class="h-4 w-4 rounded-full" :style="`background:${n.color}`" />
        <span class="text-base text-jet">{{ n.name }}</span>
        <span class="text-base text-jet font-semibold">{{ n.value }}%</span>
      </div>
    </div>
    <div
      class="w-full flex justify-center mt-7"
      v-if="edit && Number(annualGrossPay) !== 100"
    >
      <Badge
        :label="
          ` ${100 - annualGrossPay}% of your gross earnings are
         unallocated. Add more pay items to get to 100%.`
        "
        variant="secondary p-2"
        background-color="rgba(234, 60, 83, 0.15)"
        color="#EA3C53"
        style="padding:1rem important;"
      />
    </div>
    <!-- Edit Annual Gross Pay Section  -->
    <div class=" flex flex-col space-y-8" v-if="!edit">
      <div v-for="(item, i) in regularEarnings.data" :key="i">
        <span class=" inline-block">
          <span
            class=" inline-block py-1 px-1 rounded-full"
            :style="{ backgroundColor: item.color }"
          ></span>
          <span class=" inline-block px-2 capitalize "> {{ item.label }}</span>
        </span>
        <span class=" inline-block float-right">
          {{ item.color }}
          <span class=" border rounded-md py-2 px-2">
            <span> % </span>
            <input
              type="number"
              max="100"
              class=" outline-none text-sm"
              v-model="item.value"
            />
          </span>
        </span>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import RightSideBar from "@/components/RightSideBar";
import DonutChart from "@/components/donutChart";

export default {
  components: {
    RightSideBar,
    Badge,
    DonutChart
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      btnLabel: "Edit Rates",
      edit: true,
      labels: [],
      earningData: [],
      defaultData: 100.0,
      loading: true,
      AppLabel: [],
      action: "editRate",
      annualGrossPay: "",
      regularEarnings: {
        data: []
      },
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        cutoutPercentage: 60,
        legend: false,

        responsive: true,
        maintainAspectRatio: false
      },
      styles: {
        height: `250px`,
        width: `250px`,
        position: "relative"
      }
    };
  },
  watch: {
    openModal(value){
      if(value){
        this.getGrossTotal()
      }
    }
  },
  computed: {
    fixedSettingsTotal: {
      get() {
        let tempTotal = 0;
        this.regularEarnings.data.forEach(setting => {
          tempTotal += parseFloat(setting.value);
        });
        return tempTotal;
      },
      set(value) {
        return value;
      }
    }
  },
  methods: {
    invalid_permission() {
      this.$store.commit("SET_TOAST", {
        text: "You Do not have the required permission",
        color: "error"
      });
    },
    async saveData() {
      try {
         await this.$handlePrivilege("paySettings", "editRatesInViewGrossEarningsDistribution");
        if (this.fixedSettingsTotal > 100) {
          this.$toasted.error('Total Rate should not exceed 100%', {duration:5000});
        }
        else if(this.fixedSettingsTotal <= 100) {
        if (this.edit === true) {
          this.edit = !this.edit;
          this.btnLabel = "Save";
        } else {
          const result = [];
          // eslint-disable-next-line no-unused-vars
          this.regularEarnings.data.forEach(element => {
            result.push({ id: element.id, rateValue: element.value });
          });
          this.$_updateOrgGross(result)
            .then(() => {
              this.loading = true;
              this.edit = !this.edit;
              this.btnLabel = "Edit Rate";
              this.getGrossTotal();
            })
            .catch(err => {
              throw new Error(err);
            });
        }
        }
      } catch (error) {
         this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    getGrossTotal() {
      this.$_getOrgGross().then(result => {
        this.annualGrossPay = result.data.currentTotalGross || 0;
        this.fixedSettingsTotal = result.data.currentTotalGross;
        this.regularEarnings.data = result.data.paySetting.map(item => ({
          label: item.payItem.name,
          value: item.rateValue,
          color: item.color,
          id: item.id
        }));
        this.fillPreviousPayrunChartData();
        this.loading = false;
      });
    },
    fillPreviousPayrunChartData() {
      this.labels = [];
      this.AppLabel = [];
      this.earningData = [];
      const bgColor = {
        0: "#F15A29",
        1: "#490166",
        2: "#2176FF",
        3: "#13B56A",
        4: "#878E99",
        5: "#E99323",
        6: "#Fc9576",
        7: "#AB6D13",
        8: "#762C37",
        9: "#58d5fe",
        10: "#39b3f5",
        11: "#75706c",
        12: "#7a9d0b",
        13: "#1723d4",
        14: "#a3a2f4",
        15: "#7cbdfd",
        16: "#f4d021",
        17: "#7b36f",
        18: "#f87ad5",
        19: "#878ffe",
        20: "#d2207c"
      };
      const background = [];
      if (this.edit) {
        this.defaultData = 100.0;
        this.regularEarnings.data.forEach((element, index) => {
          if (this.annualGrossPay >= 100) {
            this.defaultData = 0;
          } else {
            this.defaultData -= parseFloat(element.value);
            this.defaultData = this.defaultData.toLocaleString(2);
          }
          this.labels.push(element.label);
          this.earningData.push(element.value);
          background.push(bgColor[index + 1]);

          this.AppLabel.push({
            name: element.label,
            value: element.value,
            color: bgColor[index + 1]
          });
        });
        this.chartData = {
          labels: this.defaultData
            ? ["Unallocated", ...this.labels]
            : [...this.labels],
          datasets: [
            {
              spacing: 10,
              offset: 10,
              data: this.defaultData
                ? [this.defaultData, ...this.earningData]
                : [...this.earningData],
              backgroundColor: this.defaultData
                ? ["#F0E6FE", ...background]
                : [...background]
            }
          ]
        };
      } else {
        this.regularEarnings.data.forEach(element => {
          this.labels.push(element.label);
          this.earningData.push(element.value);
        });
        this.chartData = {
          labels: this.defaultData
            ? ["Unallocated", ...this.labels]
            : [...this.labels],
          datasets: [
            {
              data: this.defaultData
                ? [this.defaultData, ...this.earningData]
                : [...this.earningData],
              backgroundColor: ["#F0E6FE"]
            }
          ]
        };
      }
    },
    closeModal() {
      this.$emit("close");
      this.edit = true;
      this.btnLabel = "Edit Rates";
    }
  },
};
</script>

<style scoped></style>
