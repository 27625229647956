<template>
  <div>
    <div v-if="!showError">
      <card>
        <card-footer class="p-5" />
      </card>
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <sTable
        v-else-if="DeductionData.length"
        :headers="EarningsHeader"
        :items="DeductionData"
        class="w-full p-5 mb-4"
        aria-label="deduction table"
        :has-number="false"
        :loading="false"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.payItem">
            {{ item.data.payItem.name }}
          </span>
          <span v-else-if="item.rateType">
            {{ item.data.rateType.label }}
          </span>

          <div class="flex" v-else-if="item.id">
            <Menu left top="-15" class="p-0" margin="30">
              <template v-slot:title>
                <icon
                  icon-name="more_icon"
                  size="xsm"
                  class="-mt-1"
                  style="padding: 3px 10px; width: 36px; height: 36px"
                />
              </template>
              <div style="width: 158px; height: 80px" class="py-3 px-2">
                <div
                  class="flex py-1 px-1 h-8 cursor-pointer"
                  @click="enableEditItem(item.data)"
                >
                  <icon
                    icon-name="edit"
                    class-name="text-blueCrayola mr-2"
                    size="xs"
                  />
                  <p class="pt-1" style="font-size: 14px">View and Edit</p>
                </div>
                <div
                  class="flex px-1 py-1 more h-8 cursor-pointer"
                  @click="delModal(item.data.id)"
                >
                  <icon
                    icon-name="delete"
                    class-name="text-desire mr-2"
                    size="xs"
                  />
                  <p class="pt-1" style="font-size: 14px">Delete</p>
                </div>
              </div>
            </Menu>
          </div>
        </template>
      </sTable>

      <div
        class="w-full h-auto flex flex-col"
        style="align-items: center"
        v-else
      >
        <div
          class="w-5/12 h-auto mt-5 mb-5 flex"
          style="justify-content: center"
        >
          <img src="@/assets/images/Deductions-Illustration.png" alt="" />
        </div>
        <div class="w-8/12 h-auto text-center">
          <p class="mt-5 mb-5 tracking-wide">
            An Overview of Pay items for deduction can be found here. This is
            empty now.
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <error-page />
    </div>
    <Modal v-if="modal">
      <Card class="p-5 flex flex-col max-w-max">
        <div class>
          <div class="flex text-center">
            <p class="font-bold ml-4 my-5">
              Are you sure you want to delete this Pay Item?
            </p>
          </div>
          <div class="flex justify-center">
            <Button class="bg-dynamicBackBtn text-white" @click="Del"> Yes </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="modal = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";
import Loader from "@/components/Loader";
import ErrorPage from "@/modules/Admin/error403";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Modal from "@/components/Modal";
import CardFooter from "@/components/CardFooter";

export default {
  components: {
    STable,
    Menu,
    Loader,
    ErrorPage,
    Button,
    Modal,
    CardFooter,
    Card,
  },
  data() {
    return {
      loading: true,
      EarningsHeader: [
        { title: "Pay Item", value: "payItem", width: 20 },
        { title: "Name in Payslip", value: "nameOnPayslip", width: 20 },
        { title: "Earning Type", value: "payType", width: 20 },
        { title: "Rate Type", value: "rateType", width: 30 },
        { title: "Rate Value", value: "rateValue", width: 10 },
        {
          title: "",
          value: "id",
          image: true,
          width: 8,
        },
      ],
      DeductionData: [],
      deleteId: "",
      modal: false,
      showError: false,
    };
  },

  methods: {
    async delModal(id) {
      try {
        await this.$handlePrivilege("paySettings", "deleteDeductions");
        this.deleteId = id;
        this.modal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    Del() {
      this.loading = true;
      this.$_deletePayItemSettings(this.deleteId)
        .then(() => {
          this.deleteId = "";
          this.modal = false
          this.getEarningPayItems();
        })
        .catch(() => {
          this.modal = false
          this.$toasted.error(
            "Advance or Loan  Pay setting cannot be deleted because it has been used in ESS",
            { duration: 5000 }
          );
          this.loading = false
        });
    },
    getEarningPayItems() {
      this.loading = true;
      this.$_getDeductionPayItems().then((result) => {
        this.DeductionData = result.data.paySettings;
        this.loading = false;
      });
    },
    async enableEditItem(data) {
      try {
        await this.$handlePrivilege("paySettings", "view&EditDeductions");
        this.$router.push({
          name: "EditDeduction",
          params: { id: data.id },
          query: {
            payType: data.payItem.name,
          },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("paySettings", "accessDeductionsSettings");
      this.getEarningPayItems();
    } catch (error) {
      this.$toasted.error("You do not have permission to perform this task", {
        duration: 5000,
      });
    }
  },
};
</script>

<style></style>
