<template>
  <div class="py-6 w-full px-3">
    <h1 class="text-xl text-left font-bold flex mb-0 -mt-2">
      Pay Settings
    </h1>
    <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
      <Card>
        <Tabs
          class="mt-2 mx-2"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
      </Card>
      <div>
        <PaySchedule v-if="activeTab === 'Pay Schedule'" />
        <Earnings v-if="activeTab === 'Earnings'" />
        <Deductions v-if="activeTab === 'Deductions'" />
        <Compliance v-if="activeTab === 'Compliance (Pension, PAYE, NHF)'" />
      </div>
  </div>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Card from "@/components/Card";
import Earnings from "./Earnings";
import PaySchedule from "./PaySchedule";
import Deductions from "./Deductions";
import Compliance from "./Compliance";

export default {
  components: {
    Tabs,
    Card,
    Earnings,
    Deductions,
    PaySchedule,
    Compliance
  },
  data() {
    return {
      activeTab: this.$route.query.payType || "Pay Schedule",
      showDescription: false,
      enabledView: false,
      kpaModal: false,
      tabsItems: [
        "Pay Schedule",
        "Earnings",
        "Deductions",
        "Compliance (Pension, PAYE, NHF)"
      ]
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.payType === "Pay Schedule") {
          this.activeTab = "Pay Schedule";
        }
        if (this.$route.query.payType === "Earnings") {
          this.activeTab = "Earnings";
        }
        if (this.$route.query.payType === "Deductions") {
          this.activeTab = "Deductions";
        }
      },
      immediate: true
    }
  },
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
</style>
